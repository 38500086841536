<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="list">
						<div class="item" v-for="bonus in bonuses" @click="viewItem(bonus)" v-bind:class="{active: benefitSelected.id == bonus.id}">
							<div class="name">{{ bonus.name }}</div>
							<div class="actions">
								<button @click.stop="editItem(bonus)"><icon-edit /></button>
								<button v-if="bonus.is_deletable" @click.stop="showModal('delete', {type: 'bonus', from: 'nomenclature_bonus', model: bonus})"><icon-trash /></button>
							</div>
						</div>
						<div class="item-empty" v-if="!bonuses.length">
							{{ $t('nomenclature.benefits.no-result') }}
						</div>
					</div>
					<div class="crud" id="list-items">
						<div class="box" v-if="viewCrud == 'create'">
							<div class="header">
								<div class="icon-header"><icon-benefit /></div>
								<div class="name">{{ $t('nomenclature.benefits.create') }}</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
                                        <div v-if="$v.benefit.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.name.$error}">
										<div class="icon-right" v-if="benefit.name != ''" @click.stop="benefit.name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('skills.name-ph')" class="input-text no-icon" v-model="benefit.name">
									</div>
								</div>
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.calcul-type') }}*</label>
                                        <div v-if="$v.benefit.selectedPercentage.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.selectedPercentage.$error}">
                                        <div class="icon-left"><icon-calculator /></div>
                                        <div class="icon-right" v-if="benefit.selectedPercentage != ''" @click.stop="benefit.selectedPercentage = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="benefit.selectedPercentage"
                                            v-bind:class="{populate: benefit.selectedPercentage != ''}"
                                            class="select-tags-tbf"
                                            :options="percentageOptions"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            @input="benefit.value = ''"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('salary.benefits.calcul-type-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div v-if="benefit.selectedPercentage.id == 1" class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.percentage') }}*</label>
                                        <div v-if="$v.benefit.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.value.$error}">
                                        <div class="icon-left"></div>
                                        <input type="text" class="input-text no-icon" :placeholder="$t('salary.benefits.percentage-ph')" v-model="benefit.value">
                                    </div>
                                </div>
                                <div v-if="benefit.selectedPercentage.id == 2" class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.fix_sum') }}*</label>
                                        <div v-if="$v.benefit.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.value.$error}">
                                        <div class="icon-left"></div>
                                        <input type="number" class="input-text no-icon" :placeholder="$t('salary.benefits.fix_sum-ph')" v-model="benefit.value">
                                    </div>
                                </div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.benefits.is_permanent') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="benefit.is_permanent = 0" v-bind:class="{active: benefit.is_permanent === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="benefit.is_permanent = 1" v-bind:class="{active: benefit.is_permanent === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.benefits.include_sum_in_co_base') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="benefit.include_sum_in_CO_base = 0" v-bind:class="{active: benefit.include_sum_in_CO_base === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="benefit.include_sum_in_CO_base = 1" v-bind:class="{active: benefit.include_sum_in_CO_base === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.benefits.bonus_base') }}*</label>
                                        <div v-if="$v.benefit.selectedBase.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.selectedBase.$error}">
                                        <div class="icon-left"><icon-benefit /></div>
                                        <div class="icon-right" v-if="benefit.selectedBase != ''" @click.stop="benefit.selectedBase = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="benefit.selectedBase"
                                            v-bind:class="{populate: benefit.selectedBase != ''}"
                                            class="select-tags-tbf"
                                            :options="optionsBases"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('nomenclature.benefits.bonus_base-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.benefits.bonus_type') }}</label>
                                    </div>
                                    <div class="input-box bg-white">
                                        <div class="icon-left"><icon-benefit /></div>
                                        <div class="icon-right" v-if="benefit.selectedType != ''" @click.stop="benefit.selectedType = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="benefit.selectedType"
                                            v-bind:class="{populate: benefit.selectedType != ''}"
                                            class="select-tags-tbf"
                                            :options="optionsTypes"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('nomenclature.benefits.bonus_type-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.add') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'edit'">
							<div class="header">
								<div class="icon-header"><icon-benefit /></div>
								<div class="name">{{ benefitSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
                                        <div v-if="$v.benefit.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box" v-bind:class="[{has_error: $v.benefit.name.$error},{'bg-gray': !benefitSelected.is_deletable},{'bg-white': benefitSelected.is_deletable}]">
										<div class="icon-right" v-if="benefit.name != '' && benefitSelected.is_deletable" @click.stop="benefit.name = ''"><icon-close class="icon-clear" /></div>
										<input :disabled="!benefitSelected.is_deletable" type="text" :placeholder="$t('skills.name-ph')" class="input-text no-icon" v-model="benefit.name">
									</div>
								</div>
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.calcul-type') }}*</label>
                                        <div v-if="$v.benefit.selectedPercentage.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box" v-bind:class="[{has_error: $v.benefit.selectedPercentage.$error},{'bg-gray': !benefitSelected.is_deletable},{'bg-white': benefitSelected.is_deletable}]">
                                        <div class="icon-left"><icon-calculator /></div>
                                        <div class="icon-right" v-if="benefit.selectedPercentage != '' && benefitSelected.is_deletable" @click.stop="benefit.selectedPercentage = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="benefit.selectedPercentage"
                                            v-bind:class="{populate: benefit.selectedPercentage != ''}"
                                            class="select-tags-tbf"
                                            :options="percentageOptions"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            @input="benefit.value = ''"
                                            :disabled="!benefitSelected.is_deletable"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('salary.benefits.calcul-type-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div v-if="benefit.selectedPercentage.id == 1" class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.percentage') }}*</label>
                                        <div v-if="$v.benefit.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.value.$error}">
                                        <div class="icon-left"></div>
                                        <input type="text" class="input-text no-icon" :placeholder="$t('salary.benefits.percentage-ph')" v-model="benefit.value">
                                    </div>
                                </div>
                                <div v-if="benefit.selectedPercentage.id == 2" class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.fix_sum') }}*</label>
                                        <div v-if="$v.benefit.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.value.$error}">
                                        <div class="icon-left"></div>
                                        <input type="number" class="input-text no-icon" :placeholder="$t('salary.benefits.fix_sum-ph')" v-model="benefit.value">
                                    </div>
                                </div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.benefits.is_permanent') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="benefit.is_permanent = 0" v-bind:class="{active: benefit.is_permanent === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="benefit.is_permanent = 1" v-bind:class="{active: benefit.is_permanent === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.benefits.include_sum_in_co_base') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="benefit.include_sum_in_CO_base = 0" v-bind:class="{active: benefit.include_sum_in_CO_base === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="benefit.include_sum_in_CO_base = 1" v-bind:class="{active: benefit.include_sum_in_CO_base === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.benefits.bonus_base') }}*</label>
                                        <div v-if="$v.benefit.selectedBase.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box" v-bind:class="[{has_error: $v.benefit.selectedBase.$error},{'bg-gray': !benefitSelected.is_deletable},{'bg-white': benefitSelected.is_deletable}]">
                                        <div class="icon-left"><icon-benefit /></div>
                                        <div class="icon-right" v-if="benefit.selectedBase != '' && benefitSelected.is_deletable" @click.stop="benefit.selectedBase = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="benefit.selectedBase"
                                            v-bind:class="{populate: benefit.selectedBase != ''}"
                                            class="select-tags-tbf"
                                            :options="optionsBases"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            :disabled="!benefitSelected.is_deletable"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('nomenclature.benefits.bonus_base-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.benefits.bonus_type') }}</label>
                                    </div>
                                    <div class="input-box" v-bind:class="[{'bg-gray': !benefitSelected.is_deletable},{'bg-white': benefitSelected.is_deletable}]">
                                        <div class="icon-left"><icon-benefit /></div>
                                        <div class="icon-right" v-if="benefit.selectedType != '' && benefitSelected.is_deletable" @click.stop="benefit.selectedType = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="benefit.selectedType"
                                            v-bind:class="{populate: benefit.selectedType != ''}"
                                            class="select-tags-tbf"
                                            :options="optionsTypes"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            :disabled="!benefitSelected.is_deletable"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('nomenclature.benefits.bonus_type-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.update') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'view'">
							<div class="header">
								<div class="icon-header"><icon-benefit /></div>
								<div class="name">{{ benefitSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf blue only-icon" @click="editItem(benefitSelected)">
										<div class="icon"><icon-edit /></div>
									</button>
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="data-item">
								<div class="label">{{ $t('salary.benefits.calcul-type') }}</div>
								<div class="text">{{ benefitSelected.is_percentage ? $t('salary.benefits.percentage') : $t('salary.benefits.fix_sum')}} {{benefitSelected.value}}</div>
                                <div class="label">{{ $t('nomenclature.benefits.is_permanent') }}</div>
								<div class="text">{{ benefitSelected.is_permanent ? $t('general.yes') : $t('general.no')}}</div>
                                <div class="label">{{ $t('nomenclature.benefits.include_sum_in_co_base') }}</div>
								<div class="text">{{ benefitSelected.include_sum_in_CO_base ? $t('general.yes') : $t('general.no')}}</div>
                                <div class="label">{{ $t('nomenclature.benefits.bonus_base') }}</div>
								<div class="text">{{ optionsBases.find(el => el.id == benefitSelected.bonus_base_id).name }}</div>
                                <div class="label" v-if="benefitSelected.bonus_type_id">{{ $t('nomenclature.benefits.bonus_type') }}</div>
								<div class="text" v-if="benefitSelected.bonus_type_id">{{ optionsTypes.find(el => el.id == benefitSelected.bonus_type_id).name}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconBenefit from '../../Icons/Benefit'
    import IconCalculator from '../../Icons/Calculator'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'
	import { required } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconBenefit,
            IconCalculator,
			LoaderSettingsPage
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'create',
				bonuses: [],
                optionsBases: [],
                optionsTypes: [],
                percentageOptions: [{id: 1, name: this.$t('salary.benefits.percentage'), value: 1}, {id: 2, name: this.$t('salary.benefits.fix_sum'), value: 0}],
                benefit: {
                    name: '',
                    selectedPercentage: '',
                    value: '',
                    is_permanent: 0,
                    include_sum_in_CO_base: 0,
                    selectedBase: '',
                    selectedType: ''

                },
				benefitSelected: '',
				error_message: '',
                is_deletable: 0
			}
		},
		async mounted(){
			await this.getBonuses()
            await this.getBonusBases()
            await this.getBonusTypes()

			setTimeout(() => {
				var title = this.$t('nomenclature-sidebar.benefits');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshBonusNomenclature', () => {
				this.viewCrud = 'create';
				this.getBonuses()
                this.getBonusBases()
                this.getBonusTypes()
			})
		},
		validations: {
            benefit:{
                name: { required },
                selectedPercentage: { required },
                value: { required },
                selectedBase: { required }
            }
		},
		methods: {
            async getBonusBases() {
                await axios.get('/bonus-bases')
				.then(({data}) => {
					this.optionsBases = data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
            async getBonusTypes() {
                await axios.get('/bonus-types')
				.then(({data}) => {
					this.optionsTypes = data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
			async getBonuses(){
				await axios.get(this.$auth.user().instance.id + '/bonuses?all=true')
				.then(({data}) => {
					this.bonuses = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName)
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.benefit.name.charAt(0).toUpperCase() + this.benefit.name.slice(1),
                        is_percentage: this.benefit.selectedPercentage ? this.benefit.selectedPercentage.value : '',
                        value: this.benefit.value,
                        is_permanent: this.benefit.is_permanent,
                        include_sum_in_CO_base: this.benefit.include_sum_in_CO_base,
                        bonus_base_id: this.benefit.selectedBase ? this.benefit.selectedBase.id : '',
                        bonus_type_id: this.benefit.selectedType ? this.benefit.selectedType.id : '',
					}

					if(type == 'create'){
						this.createBenefit(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateBenefit(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createBenefit(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/bonuses/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getBonuses()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.benefitSelected = data.data;
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			editItem(benefit){
				this.scrollTop()
				this.viewCrud = 'edit'
				
				this.benefitSelected = benefit
				this.benefit.name = benefit.name
                this.benefit.selectedPercentage = this.percentageOptions.find(el => el.value == benefit.is_percentage)
                this.benefit.value = benefit.value
                this.benefit.is_permanent = benefit.is_permanent
                this.benefit.include_sum_in_CO_base = benefit.include_sum_in_CO_base
                this.benefit.selectedBase = this.optionsBases.find(el => el.id == benefit.bonus_base_id)
                this.benefit.selectedType = this.optionsTypes.find(el => el.id == benefit.bonus_type_id)
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			updateBenefit(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/bonuses/${this.benefitSelected.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getBonuses()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.benefitSelected = this.bonuses.find(el => el.id == this.benefitSelected.id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			resetToList(from){
				this.viewCrud = 'create'
				if(from == 'item') {
					this.benefitSelected = ''
				}
				this.benefit.name = ''
                this.benefit.selectedPercentage = ''
                this.benefit.value = ''
                this.benefit.is_permanent = 0
                this.benefit.include_sum_in_CO_base = 0
                this.benefit.selectedBase = ''
                this.benefit.selectedType = ''
			},
			viewItem(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.benefitSelected = item
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			}
		}
	};
</script>